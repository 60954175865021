<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-extract :current="3"></sidebar-extract>

          <div class="page__content">
            <h1 class="page__title">Ajudar uma entidade</h1>
            <p class="page__subtitle">
              Escolha uma entidade para qual você irá fazer uma doação.
            </p>

            <form class="transfer__from">
              <div class="transfer__flex">
                <div class="transfer__col">
                  <label for="price">Valor a doar</label>

                  <r-input
                    type="money"
                    name="your-value"
                    input-class="input-money-transaction"
                    id="value"
                    placeholder="00,00"
                    v-model="form.amount_value"
                  />
                  <p>Voce pode doar qualquer quantia.</p>
                </div>

                <div class="transfer__col">
                  <div class="transfer__accounts--header">
                    <span>Selecione uma entidade</span>
                  </div>

                  <div class="modal__content modal__title strong" v-if="load">
                    <mark>Carregando...</mark>
                  </div>
                  <!-- Erro -->
                  <div class="modal__content " v-else-if="error">
                    <h4>{{ error }}</h4>
                  </div>

                  <div v-else class="banks__list">
                    <div
                      @click="form.entidade_id = item.id"
                      v-for="item in entities"
                      :key="item.id"
                      class="entity__item"
                    >
                      <input
                        type="radio"
                        name="your-bank"
                        :id="`entity_${item.id}`"
                        class="input-radio-entity"
                      />

                      <label :for="`entity_${item.id}`">
                        <div class="entities-avatar">
                          <img
                            v-if="item.avatar"
                            class="entities__item--brand"
                            :src="$help.image(item.avatar)"
                            :alt="item.name"
                          />
                          <span v-else>
                            {{ item.name[0] }}
                          </span>
                        </div>
                        <div class="entity__item--content">
                          <h4 class="entity__item--title">{{ item.name }}</h4>

                          <div class="entity__item--entry">
                            {{ item.description | cropcaracter }}
                            <a
                              @click="openModal(item)"
                              href="#"
                              title="Saiba mais"
                              data-modal="modal-info-entity"
                              >Saiba mais</a
                            >
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="donation__footer">
                    <a
                      class="button button__medium button__primary"
                      href="#"
                      @click.prevent.stop="submit()"
                      title="Doar Agora"
                    >
                      {{ loadingForm ? 'Doando...' : 'Doar Agora' }}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <modal-info
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      :selectedEntity.sync="selectedEntity"
    ></modal-info>
  </main>
</template>

<script>
import swal from 'sweetalert'
import { mapGetters, mapActions } from 'vuex'

const defaultForm = { entidade_id: '', amount_value: 0.0, doador_id: '' }

export default {
  data: () => ({
    modal: false,
    entities: [],
    form: { ...defaultForm },
    loadingForm: false,
    selectedEntity: {},
    error: null,
    load: true
  }),
  props: {
    current: Boolean
  },
  components: {
    sidebarExtract: () => import('@/components/sidebar-extracts'),
    modalInfo: () => import('./components/modal-info-entity'),
    RInput: () => import('@/components/input.vue')
  },
  methods: {
    ...mapActions('balance', ['getBalance']),
    async getEntities () {
      this.load = true
      this.error = null
      try {
        let url = 'entities/getEntities?page=1'
        url += `&user_id=${this.user.id}`
        const { data } = await this.$http.get(url)
        if (data.data.length) this.entities = data.data
      } catch (error) {
        this.error = error.response.data
      } finally {
        this.load = false
      }
    },
    async submit () {
      if (this.loadingForm) return
      if (!this.form.entidade_id || !this.form.amount_value) {
        return swal({
          title: 'Aviso!',
          text: 'Selecione a entidade e preencha o valor desejado.',
          icon: 'error'
        })
      }

      this.loadingForm = true
      try {
        this.form.doador_id = this.user.id
        const url = 'entities/storeDonation'
        const { data, status } = await this.$http.post(url, this.form)
        if (status === 200 && data.status === 'success') {
          swal({
            title: 'Sucesso!',
            icon: 'success',
            text: 'Doação realizada com sucesso.',
            buttons: false,
            timer: 2000
          })
          this.form = { ...defaultForm }
          this.getBalance()
          this.clearSelected()
          return
        }

        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar transferência.',
          buttons: false,
          timer: 2000
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar transferência.',
          buttons: false,
          timer: 2000
        })
      } finally {
        this.loadingForm = false
      }
    },
    openModal (item) {
      this.selectedEntity = item
      this.modal = true
    },
    clearSelected () {
      setTimeout(() => {
        const docs = document.querySelectorAll('.input-radio-entity')
        docs.forEach(doc => {
          doc.checked = false
        })
      }, 100)
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.getEntities()
  },
  filters: {
    cropcaracter (val) {
      let posfix = '...'
      const text = val.substr(0, 120)
      if (text[text.length - 1] === '.') posfix = '..'
      return `${text}${posfix}`
    }
  }
}
</script>

<style scoped>
.entities-avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-weight: bold;
  background: #47be71;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  margin-right: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.entity__item--content {
  width: calc(100% - 91px);
  text-align: justify;
}
</style>
